<template>
  <HelpCenterLayout>
    <section class="bg-surface-brand-hi">
      <Title
        class="mx-auto max-w-full px-24 md:max-w-[1184px] md:px-32 md:pb-28"
      />
    </section>

    <section
      v-if="userHasLastOrderline"
      class="bg-surface-brand-hi relative py-32 md:py-28"
    >
      <OrderLast
        class="relative z-10 mx-auto max-w-full px-24 md:max-w-[1184px] md:px-32"
        :orderline="orderlines!.results[0]"
      />
    </section>

    <div class="relative">
      <!--      Purple bottom -->
      <CurbBackground
        v-if="userHasLastOrderline"
        class="bg-surface-default-mid absolute top-0 -translate-y-full pb-[35px] max-md:hidden"
        ellipse-color="purple"
      />

      <!--      Grey top -->
      <CurbBackground
        class="bg-surface-default-mid absolute bottom-0"
        :class="{
          'max-md:hidden': !userHasOrders && userIsConnected,
          'md:hidden': !userHasLastOrderline,
        }"
        ellipse-color="dark-grey"
        flipped
      />
      <section
        class="relative py-28 max-md:bg-surface-brand-hi"
        :class="{
          'md:bg-surface-brand-hi': !userHasLastOrderline,
        }"
      >
        <CurbBackground
          class="bg-surface-default-mid absolute bottom-0 translate-y-full pb-[35px]"
          :class="{
            hidden: userHasLastOrderline,
            'max-md:hidden':
              !userIsConnected || (!userHasLastOrderline && !!userHasOrders),
          }"
          ellipse-color="purple"
        />
        <div
          class="relative mx-auto flex max-w-full gap-64 overflow-x-clip px-24 md:max-w-[900px] md:flex-row md:gap-32 md:px-32"
          :class="userIsConnected ? 'flex-col-reverse' : 'flex-col'"
        >
          <Login v-if="!userIsConnected" class="basis-1/2" />
          <SeoBlock
            class="relative shrink-0 grow basis-1/2"
            :class="
              (userHasOrders || !userIsConnected) &&
              `max-md:before:absolute max-md:before:inset-[-32px] max-md:before:bg-surface-default-mid max-md:before:content-['']`
            "
          />
          <Orderlines
            v-if="!!previousOrderlines"
            class="basis-1/2"
            :orderlines="previousOrderlines"
          />
        </div>
      </section>

      <section
        class="relative pb-28 md:pt-28"
        :class="{
          'max-md:pb-0': !userHasOrders && userIsConnected,
        }"
      >
        <div
          class="relative mx-auto max-w-full px-24 md:max-w-[900px] md:px-32"
        >
          <FAQSearch />
        </div>
      </section>
    </div>

    <section
      class="relative py-28 max-md:bg-static-default-mid"
      :class="{
        'max-md:bg-surface-default-mid': !userHasOrders && userIsConnected,
        'md:bg-static-default-mid': userHasLastOrderline,
      }"
    >
      <FAQCategories
        v-if="
          FAQCategoriesResponse.data && FAQCategoriesResponse.data.value?.length
        "
        :categories="FAQCategoriesResponse.data"
        class="relative mx-auto max-w-full px-24 md:max-w-[1184px] md:px-32"
      />
    </section>

    <section
      class="relative py-28 max-md:bg-static-default-mid"
      :class="{
        'max-md:bg-surface-default-mid': !userHasOrders && userIsConnected,
        'md:bg-static-default-mid': userHasLastOrderline,
      }"
    >
      <div class="mx-auto max-w-full px-24 md:max-w-[1184px] md:px-32 md:pb-72">
        <Reassurance />

        <div
          class="mt-32 flex flex-col gap-32 px-24 py-24 md:flex-row md:items-center md:py-16"
        >
          <div class="flex basis-1/2 items-center gap-12">
            <LiveChatButton />
          </div>
          <div class="flex basis-1/2 items-center gap-12">
            <RevButton variant="primary"> Phone support </RevButton>
          </div>
        </div>

        <FooterBlock class="mt-32" />
      </div>
    </section>
  </HelpCenterLayout>
</template>

<script setup lang="ts">
import { useHead } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useAuthStore } from '@backmarket/nuxt-module-oauth/useAuthStore'
import { RevButton } from '@ds/components/Button'

import translations from '~/scopes/help-center/Home.translations'
import CurbBackground from '~/scopes/help-center/components/CurbBackground/CurbBackground.vue'
import FAQCategories from '~/scopes/help-center/components/FAQCategories/FAQCategories.vue'
import FAQSearch from '~/scopes/help-center/components/FAQSearch/FAQSearch.vue'
import FooterBlock from '~/scopes/help-center/components/FooterBlock/FooterBlock.vue'
import Login from '~/scopes/help-center/components/Login/Login.vue'
import OrderLast from '~/scopes/help-center/components/OrderLast/OrderLast.vue'
import Orderlines from '~/scopes/help-center/components/Orderlines/Orderlines.vue'
import Reassurance from '~/scopes/help-center/components/Reassurance/Reassurance.vue'
import SeoBlock from '~/scopes/help-center/components/SeoBlock/SeoBlock.vue'
import Title from '~/scopes/help-center/components/Title/Title.vue'
import { useFAQCategories } from '~/scopes/help-center/composables/useFAQCategories'
import { useLastOrderLines } from '~/scopes/help-center/composables/useLastOrderLines'
import HelpCenterLayout from '~/scopes/help-center/layouts/HelpCenterLayout.vue'

import LiveChatButton from './components/LiveChatButton.vue'

const authStore = useAuthStore()

const FAQCategoriesResponse = await useFAQCategories()

const i18n = useI18n()

const { data: orderlines } = await useLastOrderLines()

const previousOrderlines = computed(() => {
  if (
    !orderlines.value?.results?.length ||
    orderlines.value?.results?.length === 1
  )
    return null

  return orderlines.value?.results?.slice(1)
})

const userIsConnected = computed(() => authStore.authenticated)
const userHasOrders = computed(() => !!orderlines.value?.results?.length)

const userHasLastOrderline = computed(
  () => orderlines.value?.results?.[0] || null,
)

useHead({
  title: i18n(translations.helpCenterPageTitle),
  htmlAttrs: { lang: useI18nLocale() },
})
</script>
