<template>
  <ClientOnly>
    <RevButton v-if="loaded" variant="primary" @click="handleClick">
      Live Chat
    </RevButton>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'

import { RevButton } from '@ds/components/Button'

import { usePreTradeInZendesk } from '../../../useZendesk'

const config = useRuntimeConfig()
const widgetPreTradeInUrl = `https://static.zdassets.com/ekr/snippet.js?key=${config.public.FF_ENABLE_CARE_ZENDESK_PRE_TRADE_IN_KEY}`

const loaded = usePreTradeInZendesk(widgetPreTradeInUrl)

const handleClick = () => {
  window.zE('messenger', 'show')
  window.zE('messenger', 'open')
}
</script>
