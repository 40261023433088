<template>
  <div
    class="bg-float-default-low shadow-short rounded-lg px-24 py-24 md:px-56"
  >
    <h2 class="font-weight-heading-2 heading-2">
      {{ i18n(translations.title) }}
    </h2>

    <RevInputAutocomplete
      id="help-center-search"
      v-model="search"
      class="mt-8 h-48"
      :clearButtonAriaLabel="i18n(translations.clearButtonAriaLabel)"
      :has-cleaning-icon="false"
      :icon="IconSearch"
      :label="i18n(translations.howCanWeHelp)"
      :options="options"
      type="text"
      @keyup.enter="onSearch"
      @select-item="(s) => handleSelectedItem(s as SearchArticle)"
    />
  </div>
</template>

<script setup lang="ts">
import { navigateTo } from '#imports'
import { computed } from 'vue'

import { type SearchArticle } from '@backmarket/http-api/src/api-specs-help-center/help-center/types/SearchArticles'
import { useZendeskFaqBaseUrl } from '@backmarket/nuxt-layer-base/composables/useZendeskFaqBaseUrl'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDebouncedRef } from '@backmarket/utils/composables/useDebouncedRef'
import { RevInputAutocomplete } from '@ds/components/InputAutocomplete'
import { IconSearch } from '@ds/icons/IconSearch'

import { useFAQArticlesSearch } from '../../composables/useFAQArticlesSearch'

import translations from './FAQSearch.translations'

const MAX_OPTIONS = 3
const i18n = useI18n()

const search = defineModel<string>({ default: '' })

const debouncedSearch = useDebouncedRef(search, 200)

const { data } = useFAQArticlesSearch({
  query: debouncedSearch,
})

const options = computed<(SearchArticle | { label: string })[]>(() => {
  if (!Array.isArray(data.value)) {
    return []
  }

  return [
    ...data.value.slice(0, MAX_OPTIONS),
    { label: i18n(translations.seeMoreResult) },
  ]
})

const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

function onSearch() {
  const searchParams = new URLSearchParams({
    utf8: decodeURI('%E2%9C%93'),
    query: search.value,
  })

  const externalHelpCenterSearchUrl = new URL(
    zendeskFaqBaseUrl.concat('/search'),
  )
  externalHelpCenterSearchUrl.search = searchParams.toString()

  navigateTo(externalHelpCenterSearchUrl.href, { external: true })
}

function handleSelectedItem(selectedItem: SearchArticle) {
  if (typeof selectedItem.url !== 'undefined') {
    navigateTo(selectedItem.url, { external: true })

    return
  }

  onSearch()
}
</script>
