import { getOrderlineList } from '@backmarket/http-api/src/api-specs-my-orders-api/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useLastOrderLines() {
  return useHttpFetch(getOrderlineList, {
    queryParams: {
      pageSize: 4,
    },
    immediate: true,
  })
}
