<template>
  <div>
    <h2 class="heading-2">{{ i18n(translations.title) }}</h2>

    <div class="mt-32">
      <div class="grid gap-32 max-md:hidden md:grid-cols-2 lg:grid-cols-3">
        <RevButtonCard
          v-for="category in categories"
          :key="category.id"
          class="bg-float-default-low flex h-[126px] items-center gap-12 px-24 py-32"
          :data-qa="getCategoryDataQA(category.id.toString())"
          :to="category.url"
        >
          <div
            class="bg-static-default-mid rounded-full flex size-[62px] shrink-0 items-center justify-center p-14"
          >
            <component
              :is="FAQ_CATEGORIES_ICONS[category.id] || IconInfo"
              :label="category.name"
            />
          </div>

          <h3 class="heading-3">
            {{ category.name }}
          </h3>
        </RevButtonCard>
      </div>

      <RevList
        class="bg-action-default-low shadow-short rounded-md md:hidden"
        :hasExternalBorders="false"
        role="listbox"
      >
        <RevListItemInteractive
          v-for="category in categories"
          :key="category.id"
          class="items-center"
          :data-qa="getCategoryDataQA(category.id.toString())"
          :to="category.url"
        >
          <template #prefix>
            <div class="flex size-[24px] items-center justify-center">
              <component
                :is="FAQ_CATEGORIES_ICONS[+category.id] || IconInfo"
                :label="category.name"
              />
            </div>
          </template>
          <template #label>
            <span class="body-1">
              {{ category.name }}
            </span>
          </template>
          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemInteractive>
      </RevList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { type MaybeRef, computed, unref } from 'vue'

import type { FAQCategories } from '@backmarket/http-api/src/api-specs-help-center/help-center/types/FAQCategories'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonCard } from '@ds/components/ButtonCard'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconAvatar } from '@ds/icons/IconAvatar'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconContact } from '@ds/icons/IconContact'
import { IconInfo } from '@ds/icons/IconInfo'
import { IconMoney } from '@ds/icons/IconMoney'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconRefund } from '@ds/icons/IconRefund'
import { IconShoppingBag } from '@ds/icons/IconShoppingBag'
import { IconSiren } from '@ds/icons/IconSiren'
import { IconSwap } from '@ds/icons/IconSwap'
import { IconTools } from '@ds/icons/IconTools'
import { IconTruck } from '@ds/icons/IconTruck'

import translations from './HelpCenterFAQCategories.translations'

const props = defineProps<{
  categories: MaybeRef<FAQCategories | null>
}>()

const i18n = useI18n()

const categories = computed(() => Array.from(unref(props.categories) || []))

const config = useRuntimeConfig()

const FAQ_CATEGORIES_ICONS = {
  [config.public.ZENDESK_CATEGORY_ACCOUNT_ID]: IconAvatar,
  [config.public.ZENDESK_CATEGORY_BUYING_ID]: IconShoppingBag,
  [config.public.ZENDESK_CATEGORY_CONTACT_AND_SUPPORT_ID]: IconContact,
  [config.public.ZENDESK_CATEGORY_PAYMENTS_ID]: IconMoney,
  [config.public.ZENDESK_CATEGORY_PROBLEM_WITH_MY_ORDER_ID]: IconSiren,
  [config.public.ZENDESK_CATEGORY_RETURNS_ID]: IconRefund,
  [config.public.ZENDESK_CATEGORY_SELLS_ID]: IconSwap,
  [config.public.ZENDESK_CATEGORY_SHIPPING_ID]: IconTruck,
  [config.public.ZENDESK_CATEGORY_WARRANTY_ID]: IconQuality,
  [config.public.ZENDESK_CATEGORY_TROUBLESHOOTING_ID]: IconTools,
} as const

const FAQ_CATEGORIES_DATA_ID = {
  [config.public.ZENDESK_CATEGORY_ACCOUNT_ID]: 'faq-category-account',
  [config.public.ZENDESK_CATEGORY_BUYING_ID]: 'faq-category-buying',
  [config.public.ZENDESK_CATEGORY_PAYMENTS_ID]: 'faq-category-payments',
  [config.public.ZENDESK_CATEGORY_RETURNS_ID]: 'faq-category-returns',
  [config.public.ZENDESK_CATEGORY_SELLS_ID]: 'faq-category-sells',
  [config.public.ZENDESK_CATEGORY_SHIPPING_ID]: 'faq-category-shipping',
  [config.public.ZENDESK_CATEGORY_WARRANTY_ID]: 'faq-category-warranty',
} as const

function getCategoryDataQA(categoryId: string) {
  return (
    FAQ_CATEGORIES_DATA_ID[categoryId] ||
    `faq-category-${categoryId.toString()}`
  )
}
</script>
